import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LocalStorageUtils } from '../../_utils/localstorage';
import { Router } from '@angular/router';

import { AccountService } from 'app/account/account.service';
import { CancelHttpRequestsService } from '../cancel-http-requests.service';
import { NotificationService } from '../notification.service';

import { Globals } from 'app/_models/globals';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
    constructor(
        private router: Router,
        private accountService: AccountService,
        private cancelHttpRequestsService: CancelHttpRequestsService,
        private notificationService: NotificationService,
        private globals: Globals) { }

    intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<any> {
        const localStorageUtils = new LocalStorageUtils();
        const errorRequest = newRequest.handle(request);

        return errorRequest.pipe(
            catchError((err) => {
                switch (err.status) {
                    case 401:
                        // Se erro 401, desloga e redireciona para a tela de login
                        this.cancelHttpRequestsService.cancelPendingRequests();

                        // Se não tiver tido problema no próprio logout
                        if (err.url.indexOf('account/logout') < 0)
                            this.accountService.logout();

                        return of(err.message);

                    case 403:
                        // Se erro 403, redireciona para a home page
                        this.cancelHttpRequestsService.cancelPendingRequests();
                        let user = localStorageUtils.obterUsuario();

                        if (user && user.homePage)
                            this.router.navigate([user.homePage]);
                        else
                            this.accountService.logout();

                        this.notificationService.showErrorToastr(this.globals.translate('geral.not-found.acesso-negado'));
                        return of(err.message);

                    case 429:
                        this.cancelHttpRequestsService.cancelPendingRequests();
                        this.notificationService.showErrorToastr(this.globals.translate('geral.erro.limite-requisicoes'));
                        return of(err.message);

                    default:
                        const error = err.error || err;
                        return throwError(error);
                }
            })
        );
    }
}
